import Vue from 'vue'
import axios from 'axios'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue';
import router from './routers'
import store from './store'
import App from './App.vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
// import vcolorpicker from 'vcolorpicker'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
//import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
// import 'core-js'
import './assets/common.less'

import vueAppend from 'vue-append'
Vue.use(vueAppend);
Vue.use(Antd);
Vue.use(VueDirectiveImagePreviewer, {
  background: {     // or : background: '#000'
    color: '#000' // or rgba or rgb     // or image: 'url(xxx)'
  },
  // transition
  animate: {
    duration: 500,
    delay: 300
  },
  // loading (not supported)
  loading: {
    image: ''
  },
   zIndex: 9999, 
  // cursor(css)
  cursor: 'pointer',
  clickMethod: 'doubleClick'   // click or doubleClick(not supported)
})
Vue.component('vue-ueditor-wrap', VueUeditorWrap)
Vue.prototype.axios = axios
Vue.prototype.$store = store
Vue.config.productionTip = false


//公共token
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem("Authorization");
  return config;
}, error => {
  console.log(error)
})

//状态码
var code = function (res) {
  if (res.data.result_code == 10000) {
    return true;
  } else if (res.data.result_code == 10001) {
    window.localStorage.setItem("Authorization", "");
    window.localStorage.setItem("name", "");
    axios.defaults.headers.post["Authorization"] = "";
    message.error(res.data.result_msg);
    store.commit("changeToken", '')
    router.push("/login");
    return false;
  } else {
    message.error(res.data.result_msg);
    return false;
  }
}
Vue.prototype.$code = code


//退出登录
var logOut = function () {
  window.localStorage.setItem("Authorization", "");
  window.localStorage.setItem("nickname", "");
  axios.defaults.headers.post["Authorization"] = "";
  router.push("/login");
};
Vue.prototype.$logOut = logOut;

//获取时间日期
Vue.prototype.$unixTimeToDateTime = function (unixtime) {
  var now = new Date(unixtime); // 依情况进行更改 * 1
  var y = now.getFullYear();
  var m = now.getMonth() + 1;
  var d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
};


//获取城市列表
Vue.prototype.$getCity = async function (level, parentId) {
  var data = {
    fid: parentId,
  };
  var cityList = []
  await axios.get("/super_admin_api/admin_user/district", {
    params: data,
  }).then((res) => {
    if (code(res)) {
      cityList = res.data.result_object;
      for (var i = 0; i < cityList.length; i++) {
        cityList[i].isLeaf = false;
      }
    }
  });
  return cityList;
},

window.addEventListener('resize', () => {
  store.commit("changeScreenWidth", document.body.clientWidth)
  store.commit("changeScreenHeight", document.body.clientHeight)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
